import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { getDocs, collection, query, orderBy, deleteDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';
import { Container, Stack, Button, Table, Form, Col, Row } from "react-bootstrap";
import Modal from "react-modal";
import { RecipeForm } from './RecipeForm';
import { roundToDollars } from '../utils/MoneyUtils';
import { CustomizedTypeahead } from './CustomizedTypeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';


export class RecipeList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allDocs: [],
      docRef: "",
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
      previousRef: "",
      isDeleteConfirmOpen: false,
      description: "",
      deleteDocRef: "",
      mode: "Add",
      showForm: false,
      didSave: true,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.COLLECTION = "recipes";

  }

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
      docMap: new Map(),
      didSave: true,
    }, () => {
      this.readDocs();
    });

  }

  toggleMode = (mode) => {
    this.setState({
      docRef: "",
      mode: mode,
      showForm: true,
      didSave: false,
    });
  }


  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      description: description,
      isDeleteConfirmOpen: !this.state.isDeleteConfirmOpen,
    });
  }

  handleUpdate = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: 'Edit',
      didSave: false,
    });
  }

  handleCopy = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: 'Copy',
      didSave: false,
    });
  }

  handleView = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: 'View',
      didSave: true,
    });
  }

  handleDelete = async (docRef) => {
    await deleteDoc(doc(database, this.COLLECTION, docRef));
    this.state.docMap.delete(docRef);
    this.setState({
      deleteDocRef: "",
      isDeleteConfirmOpen: false,
    });
  }

  async readDocs() {
    let allDocs = new Array();
    let tempOptions = new Array();
    this.setState({
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
    });

    try {
      const docQuery = query(collection(database, this.COLLECTION), orderBy("name", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.docMap.set(doc.id, doc.data());
        this.state.refMap.set(doc.id, React.createRef());
        let option = {id: doc.id, value: doc.data().name};
        tempOptions.push(option);
        allDocs.push(doc);
      });
      this.setState({ 
        allDocs: allDocs,
        options: tempOptions,
       });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  componentDidMount() {
    this.readDocs();
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (!this.state.didSave) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  scrollToView(ref) {
    if (this.state.previousRef !== '') {
      let oldRef = this.state.previousRef;
      oldRef.current.style.background = 'white';
      this.setState({
        previousRef: "",
      })
    }      
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      ref.current.style.background = 'gray';
      this.setState({
        previousRef: ref,
      })
    });
  }

  renderTableRow = (docRef, doc) => {

    return (
      <tr key={docRef} ref={this.state.refMap.get(docRef)}>
        <td>{doc.name}</td>
        <td>{roundToDollars(doc.cost_per_item_high)}</td>
        <td>{roundToDollars(doc.cost_per_item_low)}</td>
        <td>{doc.low_profit_index}</td>
        <td>{doc.hours_taken}</td>
        <td>{doc.makes_qty}</td>
        <td>{roundToDollars(doc.total_ingredient_cost)}</td>
        <td>{roundToDollars(doc.total_batch_cost_high)}</td>
        <td>{roundToDollars(doc.total_batch_cost_low)}</td>
        <td>
          <Stack direction="horizontal" gap={2}>
            {/* <Button variant="dark" onClick={() => { this.toggleDeleteModal(docRef, doc.description) }}>Delete</Button>{' '} */}
            <Button variant="dark"
              size="sm"
              onClick={() => { this.handleView(docRef) }}
            >
              View
            </Button>
            <Button variant="dark"
              size="sm"
              onClick={() => { this.handleUpdate(docRef) }}
            >
              Update
            </Button>
            <Button variant="dark"
              size="sm"
              onClick={() => { this.handleCopy(docRef) }}
            >
              Copy
            </Button>
          </Stack>
        </td>
      </tr>
    );

  }


  render() {

    let docMap = this.state.docMap;

    return (
      <>
        {
          /* 
          * Packaging List
          */
        }
        <Stack gap={3}>
          {!this.state.showForm &&
            <Container>
              <Row className="justify-content-md-left">
                <Col>
                  <h2>Recipes:</h2>
                </Col>
                <Col>
                  <Button variant="dark" onClick={() => { this.toggleMode('Add') }}>Add New Recipe</Button>
                </Col> 
                <Col md="auto">
                  Find:
                </Col>
                <Col md="auto">
                  <CustomizedTypeahead
                    onChange={(selected) => {
                      console.log("selected" + selected);
                      if (selected.length > 0) {
                        let ref = this.state.refMap.get(selected[0].id);
                        this.scrollToView(ref);
                      }
                    }}
                    align='left'
                    labelKey={'value'}
                    options={this.state.options}
                  />
                </Col>            
              </Row>
            </Container>
          }
          {this.state.showForm &&
            <RecipeForm
              docRef={this.state.docRef}
              mode={this.state.mode}
              toggleForm={() => { this.toggleShowForm() }}
            />
          }
          <Modal
            className='modalDialog'
            isOpen={this.state.isDeleteConfirmOpen}
            onRequestClose={this.toggleModal}
            contentLabel="My dialog"
          >
            <div>
              Delete {this.state.description}?
            </div>
            <Button variant="dark" size="sm" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{' '}
            <Button variant="dark" size="sm" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
          </Modal>
          {!this.state.showForm &&
            <div className='scrollingTable'>
              <Table bordered hover>
                <thead>
                  <tr className='header'>
                    <th className='table-header'>Name</th>
                    <th className='table-header'>Cost per Item High Profit</th>
                    <th className='table-header'>Cost per Item Low Profit</th>
                    <th className='table-header'>Low Profit Index</th>
                    <th className='table-header'>Hours Taken</th>
                    <th className='table-header'>Makes</th>
                    <th className='table-header'>Total Ingredient Cost</th>
                    <th className='table-header'>Batch Cost High Profit</th>
                    <th className='table-header'>Batch Cost Low Profit</th>
                    <th className='table-header'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...docMap].map(([key, value]) => {
                      return this.renderTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </div>
          }
        </Stack>
      </>
    );

  }
}