import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { query, collection, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';
import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Stack
} from "react-bootstrap";
import { roundToDollars } from '../utils/MoneyUtils';


export class ProductForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      packagingMap: new Map(),
      recipeDoc: "",
      name: "",
      description: "",
      cost_high: 0.0,
      cost_low: 0.0,
      packaging: [],
      ingredient_label_file: "",
      name_label_file: "",
      google_image_file_id: "",
      on_website: false,
      packaging_cost: 0.0,
      price: "",
      recipe: "",
      recipe_qty: 0,
      validated: false,
      isOpen: false,
      showForm: false,
      modalString: "",
      chosenPackaging: "",
      chosenQuantity: "",
      deleteDocRef: "",
      deleteDocDescription: "",
      isDeletePackagingConfirmOpen: false,
    };

    this.changeValue = this.changeValue.bind(this);
    this.changeChosenPackingValue = this.changeChosenPackingValue.bind(this);
    this.handleClose = this.handleClose(this);
    this.COLLECTION = "products";
  }

  handleClose = () => this.setState({ isOpen: false });

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  }

  addPackaging = () => {
    /**
    * First remove the packaging if it exists.
    */
    let cleanedPackaging = this.state.packaging.filter(packaging => packaging.indexOf(this.state.chosenPackaging) === -1);
    cleanedPackaging.push(this.state.chosenPackaging + ":" + this.state.chosenQuantity);


    this.setState({
      showForm: !this.state.showForm,
      packaging: cleanedPackaging,
    }, () => {
      this.recalculateCosts();
    });

  }

  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      deleteDocDescription: description,
      isDeletePackagingConfirmOpen: !this.state.isDeletePackagingConfirmOpen,
    });
  }

  handleDelete = async (docRef) => {
    let cleanedPackaging = new Array();
    cleanedPackaging = this.state.packaging.filter(packaging => packaging.indexOf(docRef) === -1);
    this.setState({
      deleteDocRef: "",
      isDeletePackagingConfirmOpen: false,
      packaging: cleanedPackaging,
    }, () => {
      this.recalculateCosts();
    });
  }


  async getDocumentFromDB() {
    /**
     * first get the recipe data.
     */
    try {
      let docRef = doc(database, this.COLLECTION, this.props.docRef);
      let existingDoc = await getDoc(docRef);
      let recipeRef = doc(database, "recipes", existingDoc.data().recipe);
      let recipeDoc = await getDoc(recipeRef);
      this.setState({
        name: existingDoc.data().name,
        cost: existingDoc.data().cost,
        description: existingDoc.data().description,
        category: existingDoc.data().category,
        packaging: existingDoc.data().packaging,
        packaging_cost: existingDoc.data().packaging_cost,
        price: existingDoc.data().price,
        recipe: existingDoc.data().recipe,
        recipe_qty: existingDoc.data().recipe_qty,
        recipeDoc: recipeDoc,
        ingredient_label_file: existingDoc.data().ingredient_label_file,
        name_label_file: existingDoc.data().name_label_file,
        google_image_file_id: existingDoc.data().google_image_file_id,
        on_website: (existingDoc.data().on_website ? existingDoc.data().on_website : false) ,
      }, () => {
        this.recalculateCosts();
      });
    } catch (e) {
      console.log("Error getting document ref:" + this.props.docRef);
    }
  }

  makePackagingMap() {
    let map = new Map();
    if (!this.state.packaging || this.state.packaging.size === 0) {
      return map;
    }
    this.state.packaging.forEach(packagingRefQuantity => {
      let packagingParts = packagingRefQuantity.split(":");
      let packagingRef = packagingParts[0];
      let quantity = packagingParts[1];

      let packagingDoc = this.state.packagingMap.get(packagingRef)
      if (packagingDoc && packagingDoc !== '') {
        map.set(packagingRef, {
          "description": packagingDoc.description,
          "cost": packagingDoc.cost,
          "per": packagingDoc.per,
          "quantity": quantity,
          "total_cost": (packagingDoc.cost * quantity),
        });
      }
    });
    return map;
  }

  async getPackagingMapFromDB() {
    this.setState({
      packagingMap: new Map(),
    });

    try {
      const docQuery = query(collection(database, "packaging"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.packagingMap.set(doc.id, doc.data());
      });

    } catch (e) {
      console.log("Error getting ingredients:", e);
    }
  }

  getTotalPackagingCost(packaging) {
    let totalPackaging = 0.0;
    if (!packaging || packaging.size === 0) {
      return totalPackaging;
    }
    packaging.forEach(currentPackaging => {
      let packagingDoc = this.state.packagingMap.get(currentPackaging);
      totalPackaging = totalPackaging + packagingDoc.cost;
    })
    return totalPackaging;
  }

  componentDidMount() {
    this.getPackagingMapFromDB();
    if (this.props.docRef !== '') {
      this.getDocumentFromDB();
    }
  }

  changeChosenPackingValue(event) {
    if (event.target.id === 'packaging') {
      this.setState({ chosenPackaging: event.target.value });
    } else if (event.target.id === 'quantity') {
      this.setState({ chosenQuantity: event.target.value });
    }
  }

  changeValue(event) {
    if (event.target.id === 'name') {
      this.setState({ name: event.target.value });
    } else if (event.target.id === 'price') {
      this.setState({ price: event.target.value }, () => {
        this.recalculateCosts();
      });
    } else if (event.target.id === 'recipe') {
      this.setState({ recipe: event.target.value }, () => {
        this.recalculateCosts();
      });
    } else if (event.target.id === 'recipe_qty') {
      this.setState({ recipe_qty: event.target.value }, () => {
        this.recalculateCosts();
      });
    } else if (event.target.id === 'ingredient_label_file') {
      this.setState({ ingredient_label_file: event.target.value });
    } else if (event.target.id === 'name_label_file') {
      this.setState({ name_label_file: event.target.value });
    } else if (event.target.id === 'google_image_file_id') {
      this.setState({ google_image_file_id: event.target.value });
    } else if (event.target.id === 'description') {
      this.setState({ description: event.target.value });
    } else if (event.target.id === 'category') {
      this.setState({ category: event.target.value });
    } else if (event.target.id === 'on_website') {
      this.setState({ on_website: event.target.checked });
    }



  }

  recalculateCosts() {
    let totalPackagingCosts = this.getTotalPackagingCosts();
    let recipeCostHigh = this.getRecipeCostHigh();
    let recipeCostLow = this.getRecipeCostLow();
    let totalCostHigh = totalPackagingCosts + (recipeCostHigh * this.state.recipe_qty);
    let totalCostLow = totalPackagingCosts + (recipeCostLow * this.state.recipe_qty);

    this.setState({
      cost_high: totalCostHigh,
      cost_low: totalCostLow,
      packaging_cost: totalPackagingCosts,
    });

  }

  getRecipeCostHigh() {
    if (!this.state.recipe || this.state.recipe === '') {
      console.log('No recipe selected to calculate recipe cost!');
      return 0.0;
    }
    let recipeDoc = this.props.recipeMap.get(this.state.recipe);
    if (recipeDoc) {
      return recipeDoc.cost_per_item_high;
    } else {
      console.log('recipe ref:' + this.state.recipe + ' not found in recipeMap!');
      return 0.0;
    }
  }
  getRecipeCostLow() {
    if (!this.state.recipe || this.state.recipe === '') {
      console.log('No recipe selected to calculate recipe cost!');
      return 0.0;
    }
    let recipeDoc = this.props.recipeMap.get(this.state.recipe);
    if (recipeDoc) {
      return recipeDoc.cost_per_item_low;
    } else {
      console.log('recipe ref:' + this.state.recipe + ' not found in recipeMap!');
      return 0.0;
    }
  }

  getTotalPackagingCosts() {
    let packagingTotal = 0.0;
    if (!this.state.packaging) {
      return packagingTotal;
    }
    this.state.packaging.forEach((packagingRefQuantity) => {
      let packagingParts = packagingRefQuantity.split(":");
      let packagingRef = packagingParts[0];
      let quantity = packagingParts[1];
      let packagingDoc = this.state.packagingMap.get(packagingRef);
      packagingTotal = packagingTotal + (packagingDoc.cost * quantity);
    })
    return packagingTotal;
  }

  changeDoc = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    this.recalculateCosts();
    let ingredient_label_file = "";
    if (this.state.ingredient_label_file) {
      ingredient_label_file = this.state.ingredient_label_file;
    }
    let name_label_file = "";
    if (this.state.name_label_file) {
      name_label_file = this.state.name_label_file;
    }

    let google_image_file_id = "";
    if (this.state.google_image_file_id) {
      google_image_file_id = this.state.google_image_file_id;
    }

    let description = "";
    if (this.state.description) {
      description = this.state.description;
    }


    let docData = {
      name: this.state.name,
      description: description,
      category: this.state.category,
      packaging: this.state.packaging,
      ingredient_label_file: ingredient_label_file,
      name_label_file: name_label_file,
      google_image_file_id: google_image_file_id,
      packaging_cost: this.state.packaging_cost,
      price: this.state.price,
      recipe: this.state.recipe,
      recipe_qty: this.state.recipe_qty,
      on_website: this.state.on_website,
    };

    try {
      let documentName = "";
      if (this.props.docRef === '') {
        const regex2 = /[^A-Za-z0-9_]/ig;
        documentName = this.state.name.replaceAll(regex2, '_');
      } else {
        documentName = this.props.docRef;
      }

      const docRef = await setDoc(doc(database,
        this.COLLECTION, documentName), docData, { merge: true });
    } catch (e) {
      console.log("Error saving Recipe:", e);
    }

    this.setState({
      name: "",
      description: "",
      category: "",
      cost_high: 0.0,
      cost_low: 0.0,
      packaging: [],
      packaging_cost: 0.0,
      ingredient_label_file: "",
      name_label_file: "",
      google_image_file_id: "",
      on_website: false,
      price: "",
      recipe: "",
      recipe_qty: 0,
      validated: false,
      isOpen: true,
      modalString: "Recipe added!",
      deleteDocRef: "",
      deleteDocDescription: "",
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.toggleForm();
  }

  renderPackagingTableRow = (docRef, doc) => {

    return (
      <tr>
        <td>{doc.description}</td>
        <td>{roundToDollars(doc.cost)}</td>
        <td>{doc.per}</td>
        <td>{doc.quantity}</td>
        <td>{roundToDollars(doc.total_cost)}</td>
        <td>
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.toggleDeleteModal(docRef, doc.description)
              }}>
              Delete
            </Button>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.setState({
                  chosenPackaging: docRef,
                  chosenQuantity: doc.quantity,
                }, () => {
                  this.toggleShowForm();
                });
              }}>
              Update
            </Button>
          </Stack>
        </td>
      </tr>
    );

  }

  getRecipeSelect = (docRef, doc) => {
    return (
      <>
        <option value={docRef}>{doc.name + '- Makes:' + doc.makes_qty}</option>
      </>
    );
  }

  getPackagingSelect = (docRef, doc) => {
    return (
      <>
        <option value={docRef}>{doc.description + '- Cost:' + roundToDollars(doc.cost)}</option>
      </>
    );
  }

  render() {
    let productPackagingMap = this.makePackagingMap();
    let packagingMap = this.state.packagingMap;
    let recipeMap = this.props.recipeMap;

    return (
      <>
        {
          /* 
          * Product 
          */
        }
        <Container>
          {/*
          ------------- start Modal add packaging ---------------
           */}        
          <Modal show={this.state.showForm} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.changeDoc}>
                <Form.Group as={Col}>
                  <Form.Label as={Col} htmlFor="packaging">Packaging:</Form.Label>
                  <Form.Select
                    disabled={
                      '' !== this.state.chosenPackaging
                      && 0 !== this.state.chosenQuantity
                    }
                    required
                    value={this.state.chosenPackaging}
                    id="packaging"
                    aria-label="Default select example"
                    onChange={this.changeChosenPackingValue}
                  >
                    <option></option>
                    {
                      [...packagingMap].map(([key, value]) => {
                        return this.getPackagingSelect(key, value);
                      }
                      )
                    }
                  </Form.Select>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label as={Col} htmlFor="quantity">Qty:</Form.Label>
                    <Form.Control
                      required
                      value={this.state.chosenQuantity}
                      placeholder="0"
                      id="quantity"
                      onChange={this.changeChosenPackingValue}
                    />
                  </Form.Group>
                </Form.Group>
              </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={this.toggleShowForm}>
                Cancel
              </Button>{' '}
              <Button variant="secondary" size="sm" onClick={this.addPackaging}>
                Save Packaging
              </Button>
            </Modal.Footer>
          </Modal>
          {/*
          ------------- end Modal add packaging ---------------
           */}
          {/*
          ------------- start Modal delete packaging ---------------
           */}
          <Modal show={this.state.isDeletePackagingConfirmOpen}>
            <div>
              Delete {this.state.deleteDocDescription}?
            </div>
            <Container>
              <Button variant="dark" size="sm" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{' '}
              <Button variant="dark" size="sm" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
            </Container>
          </Modal>
          {/*
          ------------- end Modal delete packaging ---------------
           */}
          <h1>{this.props.mode} "{this.state.name}"</h1>
          <Form noValidate validated={this.state.validated} onSubmit={this.changeDoc}>
            <Button
              size="sm"
              variant="dark"
              onClick={() => { this.props.toggleForm() }}>
              Cancel
            </Button>{' '}
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              type="submit">
              Save
            </Button>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Name:</Form.Label>
              <Form.Control
                disabled={this.props.mode === 'View'}
                required
                value={this.state.name}
                placeholder="Name"
                id="name"
                onChange={this.changeValue}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="recipe">Recipe:</Form.Label>
              <Form.Select
                disabled={this.props.mode === 'View'}
                required
                value={this.state.recipe}
                id="recipe"
                aria-label="Default select example"
                onChange={this.changeValue}
              >
                <option></option>
                {
                  [...recipeMap].map(([key, value]) => {
                    return this.getRecipeSelect(key, value);
                  }
                  )
                }
              </Form.Select>
            </Form.Group>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="cost_high">Cost High Profit:</Form.Label>
              <Form.Label as={Col} htmlFor="cost_low">Cost Low Profit:</Form.Label>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  required
                  value={roundToDollars(this.state.cost_high)}
                  placeholder="0"
                  id="cost_high"
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  required
                  value={roundToDollars(this.state.cost_low)}
                  placeholder="0"
                  id="cost_low"
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="recipe_qty">Quantity per Product Package:</Form.Label>
              <Form.Label as={Col} htmlFor="price">Price:</Form.Label>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.recipe_qty}
                  placeholder="0"
                  id="recipe_qty"
                  onChange={this.changeValue}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  value={this.state.price}
                  placeholder="0"
                  id="price"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="packaging_cost">Packaging Cost:</Form.Label>
            </Row>
            <Row className="mb-3">
              <InputGroup as={Col} className="mb-3">
                <Form.Control
                  value={roundToDollars(this.state.packaging_cost)}
                  aria-label="packaging_cost"
                  placeholder="0.00"
                  id="packaging_cost"
                  disabled
                />
              </InputGroup>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Check
                  reverse
                  disabled={this.props.mode === 'View'}
                  type="checkbox"
                  checked={this.state.on_website}
                  onChange={this.changeValue}
                  id="on_website"
                  label="On Website?"
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label as={Col} htmlFor="category">Category:</Form.Label>
                <Form.Select
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.category}
                  id="category"
                  onChange={this.changeValue}
                >
                  <option key='1' ></option>
                  <option key='2' value='Bread'>Bread</option>
                  <option key='3' value='Cookies'>Cookies</option>
                  <option key='4' value='Gluten Free'>Gluten Free</option>
                  <option key='5' value='Rolls'>Rolls</option>
                  <option key='6' value='Cake'>Cake</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label as={Col} htmlFor="description">Description:</Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={this.props.mode === 'View'}
                  value={this.state.description}
                  placeholder="description"
                  id="description"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              Packaging:
            </Row>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.setState({
                  chosenPackaging: "",
                  chosenQuantity: "",
                }, () => {
                  this.toggleShowForm()
                });
              }}>
              Add Packaging
            </Button>
            <Row className="mb-3">
              <Table bordered>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Cost</th>
                    <th>Per</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...productPackagingMap].map(([key, value]) => {
                      return this.renderPackagingTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Row>
            <Row>
              <Form.Label as={Col} htmlFor="ingredient_label_file">Ingredient & Disclaimer Label Google File ID:</Form.Label>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  onChange={this.changeValue}
                  value={this.state.ingredient_label_file}
                  placeholder="Ingredient & Disclaimer Label file ID"
                  id="ingredient_label_file"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Label as={Col} htmlFor="name_label_file">Name Label Google File ID:</Form.Label>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  onChange={this.changeValue}
                  value={this.state.name_label_file}
                  placeholder="Name Label Google file ID"
                  id="name_label_file"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Label as={Col} htmlFor="google_image_file_id">Web site Image Google File ID:</Form.Label>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  onChange={this.changeValue}
                  value={this.state.google_image_file_id}
                  placeholder="Image Google File ID"
                  id="google_image_file_id"
                />
              </Form.Group>
            </Row>
            <Row>
              <Container>
                <Button
                  size="sm"
                  variant="dark"
                  onClick={() => { this.props.toggleForm() }}>
                  Cancel
                </Button>{' '}
                <Button
                  size="sm"
                  disabled={this.props.mode === 'View'}
                  variant="dark"
                  type="submit">
                  Save
                </Button>
              </Container>
            </Row>
          </Form>
        </Container>
      </>
    );

  }
}
