import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { database } from '../firebase-config';
import { Button, Stack } from "react-bootstrap";

export class Labels extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      labels: [],
    };
    this.clickMe = this.clickMe.bind(this);
  }

  clickMe() {
    alert("You clicked me!");
  }

  async componentDidMount() {

    let allLabels = new Array();

    try {
      const labelsQuery = query(collection(database, "labels"), orderBy("name", "asc"));

      const querySnapshot = await getDocs(labelsQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        allLabels.push(doc.data());
      });
      this.setState({ labels: allLabels });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }

  }

  render() {

    let labels = this.state.labels;

    return (
      <>
        {
          /* 
          * Labels section
          */
        }
        <Stack>
          {/* <div className='labelsText'> */}
            <Stack gap={2} className="col-md-5 mx-auto">
              {
                labels.map(
                  currentLabelRecord =>
                    <a href={'https://drive.google.com/uc?export=view&id=' + currentLabelRecord.google_id}
                      download={currentLabelRecord.google_id}>
                      <Button className='printButton'>
                        {currentLabelRecord.name}
                      </Button>
                    </a>
                )
              }

            </Stack>
          {/* </div> */}
        </Stack>
      </>
    );

  }
}
