import React from 'react';
import '../index.css';

export class DashboardHome extends React.Component {
  
  render() {
  
    return (
      <>
        <div className='bodyText'>
        Whatever you do, work at it with all your heart, as working for the Lord, 
        not for human masters. - Colosssians 3:23
        </div>
       
      </>
    );
  }
}
