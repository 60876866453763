import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { query, collection, orderBy, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';
import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Stack,
} from "react-bootstrap";
import { roundToDollars } from '../utils/MoneyUtils';

const customModalStyle = {
  content: {
    width: '20vw',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const default_low_profit_index = 1.4;

export class RecipeForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ingredientMap: new Map(),
      recipeIngredientMap: new Map(),
      recipeName: "",
      docRef: "",
      name: "",
      hours_taken: "",
      makes_qty: "",
      note: "",
      cost_per_item_high: "",
      cost_per_item_low: "",
      total_ingredient_cost: "",
      total_batch_cost_high: "",
      total_batch_cost_low: "",
      low_profit_index: default_low_profit_index,
      ingredients: new Array(),
      // recipe_multiplier: false,
      validated: false,
      isOpen: false,
      showForm: false,
      modalString: "",
      chosenIngredient: "",
      chosenQuantity: "",
      deleteDocRef: "",
      deleteDocDescription: "",
      isDeleteIngredientConfirmOpen: false,
    };

    this.changeValue = this.changeValue.bind(this);
    this.changeChosenIngredientValue = this.changeChosenIngredientValue.bind(this);
    this.handleClose = this.handleClose(this);
    this.COLLECTION = "recipes";
  }

  handleClose = () => this.setState({ isOpen: false });

  toggleShowForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  }

  addIngredient = () => {
    /**
     * First remove the ingredient if it exists.
     */
    let cleanedIngredients = this.state.ingredients.filter(ingredient => ingredient.indexOf(this.state.chosenIngredient) === -1);
    cleanedIngredients.push(this.state.chosenIngredient + ":" + this.state.chosenQuantity);

    this.setState({
      showForm: !this.state.showForm,
      chosenIngredient: "",
      chosenQuantity: "",
      ingredients: cleanedIngredients,
    }, () => {
      this.recalculateCosts();
    });


  }

  toggleDeleteModal = (ingredientDocRef, description) => {
    this.setState({
      deleteDocRef: ingredientDocRef,
      deleteDocDescription: description,
      isDeleteIngredientConfirmOpen: !this.state.isDeleteIngredientConfirmOpen,
    });
  }

  handleDelete = async (ingredientDocRef) => {
    let cleanedIngredients = new Array();
    cleanedIngredients = this.state.ingredients.filter(ingredient => ingredient.indexOf(ingredientDocRef) === -1);
    this.setState({
      deleteDocRef: "",
      isDeleteIngredientConfirmOpen: false,
      ingredients: cleanedIngredients,
    }, () => {
      this.recalculateCosts();
    });
  }


  async getExistingDocument() {
    /**
     * first get the recipe data.
     */
    try {
      let existingDocRef = doc(database, this.COLLECTION, this.props.docRef);
      let existingDoc = await getDoc(existingDocRef);
      let low_profit_index = existingDoc.data().low_profit_index;
      if (!low_profit_index) {
        low_profit_index = default_low_profit_index;
      }
      let documentName = existingDoc.data().name;
      let documentRef = this.props.docRef;
      let recipeName = existingDoc.data().name;
      if (this.props.mode === 'Copy') {
        documentName = '';
        documentRef = '';
      }
      this.setState({
        recipeName: recipeName,
        docRef: documentRef,
        name: documentName,
        cost_per_item: existingDoc.data().cost_per_item,
        hours_taken: existingDoc.data().hours_taken,
        makes_qty: existingDoc.data().makes_qty,
        note: existingDoc.data().note,
        cost_per_item_high: existingDoc.data().cost_per_item_high,
        cost_per_item_low: existingDoc.data().cost_per_item_low,
        total_batch_cost_high: existingDoc.data().total_batch_cost_high,
        total_batch_cost_low: existingDoc.data().total_batch_cost_low,
        total_ingredient_cost: existingDoc.data().total_ingredient_cost,
        total_high_profit: existingDoc.data().total_high_profit,
        total_low_profit: existingDoc.data().total_low_profit,
        low_profit_index: low_profit_index,
        ingredients: existingDoc.data().ingredients,
      }, () => {
        this.recalculateCosts();
      });
    } catch (e) {
      if(!this.state.note) {
        this.setState({
          note: "",
        })
      }
      console.log("Error getting document ref:" + this.props.docRef);
    }

  }

  async getIngredientMapFromDB() {
    this.setState({
      ingredientMap: new Map(),
    });

    try {
      const docQuery = query(collection(database, "ingredients"), orderBy("description", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        this.state.ingredientMap.set(doc.id, doc.data());
      });

    } catch (e) {
      console.log("Error getting ingredients:", e);
    }
  }

  makeIngredientMap() {
    let map = new Map();
    if (!this.state.ingredients || this.state.ingredients.size === 0) {
      return map;
    }
    this.state.ingredients.forEach(ingredientRefQuantity => {
      let ingredientParts = ingredientRefQuantity.split(":");
      let ingredientRef = ingredientParts[0];
      let quantity = ingredientParts[1];
      let ingredientDoc = this.state.ingredientMap.get(ingredientRef)
      if (ingredientDoc && ingredientDoc !== '') {
        map.set(ingredientRef, {
          "description": ingredientDoc.description,
          "cost": ingredientDoc.cost,
          "per": ingredientDoc.per,
          "quantity": quantity,
          "total_cost": (ingredientDoc.cost * quantity),
        });
      }
    });
    return map;
  }

  componentDidMount() {
    this.getIngredientMapFromDB();
    if (this.props.docRef !== '') {
      this.getExistingDocument();
    }
  }

  changeChosenIngredientValue(event) {
    if (event.target.id === 'ingredient') {
      this.setState({ chosenIngredient: event.target.value });
    } else if (event.target.id === 'quantity') {
      this.setState({ chosenQuantity: event.target.value });
    }
  }

  changeValue(event) {
    if (event.target.id === 'name') {
      this.setState({ name: event.target.value });
    } if (event.target.id === 'note') {
      this.setState({ note: event.target.value });
    } else if (event.target.id === 'makes_qty') {
      this.setState({ makes_qty: event.target.value }, () => {
        this.recalculateCosts();
      });
    } else if (event.target.id === 'hours_taken') {
      this.setState({ hours_taken: event.target.value }, () => {
        this.recalculateCosts();
      });
    } else if (event.target.id === 'low_profit_index') {
      this.setState({ low_profit_index: event.target.value }, () => {
        this.recalculateCosts();
      });
    }
    //else if (event.target.id === 'reciple_multiplier') {
    //   this.setState({ 
    //     previous_recipe_multiplier: recipe_multiplier,
    //     reciple_multiplier: event.target.value 
    //   }, () => {

    //     this.recalculateCosts();
    //   });
    // }
  }

  recalculateCosts() {
    /**
     * If the hours taken or makes quantity or ingredients are null or 0, just return.
     */
    if (this.state.hours_taken === ''
      || this.state.hours_taken === 0) {
      return;
    }

    if (this.state.makes_qty === ''
      || this.state.makes_qty === 0) {
      return;
    }

    if (this.state.ingredients === ''
      || this.state.ingredients.size === 0) {
      return;
    }

    let totoalIngredientsCost = this.getTotalIngredientCosts();
    let totalTimeAndMaterialCost = totoalIngredientsCost + (15.00 * this.state.hours_taken);
    let totatBatchCostHigh = totalTimeAndMaterialCost * 1.7;
    let totatBatchCostLow = totalTimeAndMaterialCost * this.state.low_profit_index;
    let costPerItemHigh = totatBatchCostHigh / this.state.makes_qty;
    let costPerItemLow = totatBatchCostLow / this.state.makes_qty;

    this.setState({
      total_ingredient_cost: totoalIngredientsCost,
      total_batch_cost_high: totatBatchCostHigh,
      total_batch_cost_low: totatBatchCostLow,
      cost_per_item_high: costPerItemHigh,
      cost_per_item_low: costPerItemLow,
    });

  }

  getTotalIngredientCosts() {
    let ingredientsTotal = 0.0;
    this.state.ingredients.forEach((ingredientRefQuantity) => {
      let ingredientParts = ingredientRefQuantity.split(":");
      let ingredientRef = ingredientParts[0];
      let quantity = ingredientParts[1];
      let ingredientDoc = this.state.ingredientMap.get(ingredientRef);
      ingredientsTotal = ingredientsTotal + (ingredientDoc.cost * quantity);
    })
    return ingredientsTotal;
  }

  getNewRecipeReference(name) {
    const regex2 = /[^A-Za-z0-9_]/ig;
    return name.replaceAll(regex2, '_');
  }

  changeDoc = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (this.state.name !== '' && this.props.mode === 'Copy') {

      let newRecipeRef = this.getNewRecipeReference(this.state.name);
      let existingDocRef = doc(database, this.COLLECTION, newRecipeRef);
      let existingDoc = await getDoc(existingDocRef);

      if (existingDoc.data()) {
        let nameElement = document.getElementById('name');
        nameElement.setCustomValidity("A recipe with this name already exists");
      }
    }

    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    this.recalculateCosts();

    let docData = {
      name: this.state.name,
      hours_taken: this.state.hours_taken,
      makes_qty: this.state.makes_qty,
      note: this.state.note,
      total_ingredient_cost: this.state.total_ingredient_cost,
      total_batch_cost_high: this.state.total_batch_cost_high,
      total_batch_cost_low: this.state.total_batch_cost_low,
      cost_per_item_high: this.state.cost_per_item_high,
      cost_per_item_low: this.state.cost_per_item_low,
      low_profit_index: this.state.low_profit_index,
      ingredients: this.state.ingredients,
    };

    try {
      let documentName = "";
      if (this.state.docRef === '') {
        documentName = this.getNewRecipeReference(this.state.name);
      } else {
        documentName = this.state.docRef;
      }

      const docRef = await setDoc(doc(database,
        this.COLLECTION, documentName), docData, { merge: true });
    } catch (e) {
      console.log("Error saving Recipe:", e);
    }

    this.setState({
      docRef: "",
      name: "",
      hours_taken: "",
      makes_qty: "",
      note: "",
      cost_per_item_high: "",
      cost_per_item_low: "",
      total_ingredient_cost: "",
      total_batch_cost_high: "",
      total_batch_cost_low: "",
      low_profit_index: default_low_profit_index,
      ingredients: new Array(),
      validated: false,
      isOpen: true,
      modalString: "Recipe added!",
      deleteDocRef: "",
      deleteDocDescription: "",
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.toggleForm();
  }

  renderIngredientTableRow = (ingredientDocRef, doc) => {

    return (
      <tr>
        <td>{doc.description}</td>
        <td>{roundToDollars(doc.cost)}</td>
        <td>{doc.per}</td>
        <td>{doc.quantity}</td>
        <td>{roundToDollars(doc.total_cost)}</td>
        <td>
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.toggleDeleteModal(ingredientDocRef, doc.description)
              }}>
              Delete
            </Button>
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              onClick={() => {
                this.setState({
                  chosenIngredient: ingredientDocRef,
                  chosenQuantity: doc.quantity,
                }, () => {
                  this.toggleShowForm();
                });
              }}>
              Update
            </Button>
          </Stack>
        </td>
      </tr>
    );

  }

  getIngredientSelect = (ingredientDocRef, doc) => {
    return (
      <>
        <option value={ingredientDocRef}>{doc.description + '- Cost:' + roundToDollars(doc.cost) + '- Per:' + doc.per}</option>
      </>
    );
  }

  render() {

    let recipeIngredientMap = this.makeIngredientMap();
    let ingredientMap = this.state.ingredientMap;

    return (
      <>
        {
          /* 
          * Packaging Add
          */
        }
        <Container>
          <Modal show={this.state.showForm} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.changeDoc}>
                <Form.Group as={Col}>
                  <Form.Label as={Col} htmlFor="ingredient">Ingredient:</Form.Label>
                  <Form.Select
                    disabled={
                      '' !== this.state.chosenIngredient
                      && 0 !== this.state.chosenQuantity
                    }
                    required
                    value={this.state.chosenIngredient}
                    id="ingredient"
                    aria-label="Default select example"
                    onChange={this.changeChosenIngredientValue}
                  >
                    <option></option>
                    {
                      [...ingredientMap].map(([key, value]) => {
                        return this.getIngredientSelect(key, value);
                      }
                      )
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Label as={Col} htmlFor="quantity">Qty:</Form.Label>
                <Form.Group as={Col} className="mb-3">
                  <Form.Control
                    value={this.state.chosenQuantity}
                    required
                    placeholder="0"
                    id="quantity"
                    onChange={this.changeChosenIngredientValue}
                  />
                </Form.Group>
              </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" size="sm" onClick={this.toggleShowForm}>
                Cancel
              </Button>{' '}
              <Button variant="secondary" size="sm" onClick={this.addIngredient}>
                Save Ingredient
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            //style={customModalStyle}
            show={this.state.isDeleteIngredientConfirmOpen}>
            <Container>
              <Row>
                Delete {this.state.deleteDocDescription}?
              </Row>
              <Row>
                <Button variant="dark" size="sm" onClick={() => { this.toggleDeleteModal() }}>Cancel</Button>{' '}
                <Button variant="dark" size="sm" onClick={() => { this.handleDelete(this.state.deleteDocRef) }}>Confirm</Button>
              </Row>
            </Container>
          </Modal>
          <h1>{this.props.mode} Recipe "{this.state.recipeName}"</h1>
          <Form noValidate validated={this.state.validated} onSubmit={this.changeDoc}>
            <Button variant="dark" size="sm" onClick={() => { this.props.toggleForm(); }}>
              Cancel
            </Button>{' '}
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              type="submit">
              Save
            </Button>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="name">Name:</Form.Label>
              <Form.Control
                disabled={this.props.mode === 'View'}
                required
                value={this.state.name}
                placeholder="Name"
                id="name"
                onChange={this.changeValue}
              />
            </Form.Group>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="hours_taken">Hours Taken:</Form.Label>
              <Form.Label as={Col} htmlFor="makes_qty">Makes:</Form.Label>
              <Form.Label as={Col} htmlFor="low_profit_index">Lower Profit Index:</Form.Label>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.hours_taken}
                  placeholder="0"
                  id="hours_taken"
                  onChange={this.changeValue}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  disabled={this.props.mode === 'View'}
                  value={this.state.makes_qty}
                  placeholder="0"
                  id="makes_qty"
                  onChange={this.changeValue}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Select className="mb-3"
                  disabled={this.props.mode === 'View'}
                  required
                  value={this.state.low_profit_index}
                  id="low_profit_index"
                  onChange={this.changeValue}
                >
                  <option value='1.6'>1.6 (60% profit)</option>
                  <option value='1.5'>1.5 (50% profit)</option>
                  <option value='1.4'>1.4 (40% profit)</option>
                  <option value='1.3'>1.3 (30% profit)</option>
                  <option value='1.2'>1.2 (20% profit)</option>
                  <option value='1.1'>1.1 (10% profit)</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="note">Notes:</Form.Label>
            </Row>            
            <Row className="mb-3">
              <Form.Control
                as="textarea"
                disabled={this.props.mode === 'View'}
                value={this.state.note}
                placeholder="notes..."
                id="note"
                onChange={this.changeValue}
              />
            </Row>            
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="total_ingredient_cost">Total Ingredient Cost:</Form.Label>
            </Row>
            <Row className="mb-3">
              <InputGroup as={Col} className="mb-3">
                <Form.Control
                  value={roundToDollars(this.state.total_ingredient_cost)}
                  aria-label="totalingredientcost"
                  placeholder="0.00"
                  id="total_ingredient_cost"
                  disabled
                />
              </InputGroup>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="cost_per_item_high">Cost per Item High Profit:</Form.Label>
              <Form.Label as={Col} htmlFor="cost_per_item_low">Cost per Item Low Profit:</Form.Label>
            </Row>
            <Row className="mb-3">
              <InputGroup as={Col} className="mb-3">
                <Form.Control
                  value={roundToDollars(this.state.cost_per_item_high)}
                  aria-label="cost_per_item_high"
                  placeholder="0.00"
                  id="cost_per_item_high"
                  disabled
                />
              </InputGroup>
              <InputGroup as={Col} className="mb-3">
                <Form.Control
                  value={roundToDollars(this.state.cost_per_item_low)}
                  aria-label="cost_per_item_low"
                  placeholder="0.00"
                  id="cost_per_item_low"
                  disabled
                />
              </InputGroup>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="total_batch_cost_high">Total Batch Cost High Profit:</Form.Label>
              <Form.Label as={Col} htmlFor="total_batch_cost_low">Total Batch Cost Low Profit:</Form.Label>
            </Row>
            <Row className="mb-3">
              <InputGroup as={Col} className="mb-3">
                <Form.Control
                  value={roundToDollars(this.state.total_batch_cost_high)}
                  aria-label="total_batch_cost_high"
                  placeholder="0.00"
                  id="total_batch_cost_high"
                  disabled
                />
              </InputGroup>
              <InputGroup as={Col} className="mb-3">
                <Form.Control
                  value={roundToDollars(this.state.total_batch_cost_low)}
                  aria-label="total_batch_cost_low"
                  placeholder="0.00"
                  id="total_batch_cost_low"
                  disabled
                />
              </InputGroup>
            </Row>
            <Container className="mb-3">
              <Row>
                <Col>
                  <Button
                    size="sm"
                    disabled={this.props.mode === 'View'}
                    variant="dark"
                    onClick={() => {
                      this.setState({
                        chosenIngredient: "",
                        chosenQuantity: "",
                      }, () => {
                        this.toggleShowForm()
                      });
                    }}>
                    Add Ingredient
                  </Button>
                </Col>
                {/* <Col>
                <Form.Label as={Col} htmlFor="recipe_multiplier">Total Batch Cost High Profit:</Form.Label>

                  <Form.Select 
                  value={this.state.recipe_multiplier}
                  aria-label="recipe_multiplier"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Form.Select>               
               </Col> */}
              </Row>
            </Container>
            <Row className="mb-3">
              Ingredients:
            </Row>
            <Row className="mb-3">
              <Table bordered>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Cost</th>
                    <th>Per</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...recipeIngredientMap].map(([key, value]) => {
                      return this.renderIngredientTableRow(key, value);
                    }
                    )
                  }
                </tbody>
              </Table>
            </Row>
            <Button variant="dark" size="sm" onClick={() => { this.props.toggleForm(); }}>
              Cancel
            </Button>{' '}
            <Button
              size="sm"
              disabled={this.props.mode === 'View'}
              variant="dark"
              type="submit">
              Save
            </Button>
          </Form>
        </Container>
      </>
    );

  }
}
