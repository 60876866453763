import * as React from "react";
import "./../index.css";
import "./../fonts/Bakerie/BakerieSmoothCondensed-Black.otf";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Stack
} from "react-bootstrap";
import { getDocs, collection, query, orderBy, deleteDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';

import Modal from "react-modal";
import { ProductForm } from './ProductForm';
import { roundToDollars } from '../utils/MoneyUtils';
import { CustomizedTypeahead } from './CustomizedTypeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

export class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipeMap: new Map(),
      packagingMap: new Map(),
      allDocs: [],
      docRef: "",
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
      previousRef: "",
      isDeleteConfirmOpen: false,
      description: "",
      deleteDocRef: "",
      mode: "Add",
      showForm: false,
      didSave: true,
      currentlySelectedId: '',
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.COLLECTION = "products";
  }

  toggleShowForm = () => {
    this.setState(
      {
        showForm: !this.state.showForm,
        docMap: new Map(),
        didSave: true,
      },
      () => {
        this.readDocs();
      }
    );
  };

  toggleMode = (mode) => {
    this.setState({
      docRef: "",
      mode: mode,
      showForm: true,
      didSave: false,
    });
  };

  toggleDeleteModal = (docRef, description) => {
    this.setState({
      deleteDocRef: docRef,
      description: description,
      isDeleteConfirmOpen: !this.state.isDeleteConfirmOpen,
    });
  };

  handleUpdate = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: "Edit",
      didSave: false,
    });
  };

  handleView = async (docRef) => {
    this.setState({
      docRef: docRef,
      showForm: true,
      mode: "View",
      didSave: true,
    });
  };

  handleDelete = async (docRef) => {
    await deleteDoc(doc(database, this.COLLECTION, docRef));
    this.state.docMap.delete(docRef);
    this.setState({
      deleteDocRef: "",
      isDeleteConfirmOpen: false,
    });
  };

  async readDocs() {
    let allDocs = new Array();
    let tempOptions = new Array();
    this.setState({
      docMap: new Map(),
      refMap: new Map(),
      options: new Array(),
    });

    try {
      const docQuery = query(collection(database, this.COLLECTION), orderBy("name", "asc"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.state.docMap.set(doc.id, doc.data());
        this.state.refMap.set(doc.id, React.createRef());
        let option = {id: doc.id, value: doc.data().name};
        tempOptions.push(option);
        allDocs.push(doc);
      });
      this.setState({ 
        allDocs: allDocs,
        options: tempOptions,
       });

    } catch (e) {
      console.log("Error getting cached document:", e);
    }
  }

  getRecipeMapFromDB = async () => {
    this.setState({
      recipeMap: new Map(),
    });

    try {
      const docQuery = query(collection(database, "recipes"));

      const querySnapshot = await getDocs(docQuery);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        this.state.recipeMap.set(doc.id, doc.data());
      });
    } catch (e) {
      console.log("Error getting ingredients:", e);
    }
  };

  componentDidMount() {
    this.readDocs();
    this.getRecipeMapFromDB();
    window.addEventListener("beforeunload", this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (!this.state.didSave) {
      e.preventDefault();
      e.returnValue = "";
    }
  }

  scrollToView(ref) {
    if (this.state.previousRef !== '') {
      let oldRef = this.state.previousRef;
      oldRef.current.style.background = 'white';
      this.setState({
        previousRef: "",
      })
    }      
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      ref.current.style.background = 'gray';
      this.setState({
        previousRef: ref,
      })
    });
  } 

  renderTableRow = (docRef, doc) => {
    let recipeCostPerItemHigh = this.state.recipeMap.get(
      doc.recipe
    ).cost_per_item_high;
    let recipeCostPerItemLow = this.state.recipeMap.get(
      doc.recipe
    ).cost_per_item_low;
    let productCostHigh =
      doc.packaging_cost + recipeCostPerItemHigh * doc.recipe_qty;
    let productCostLow =
      doc.packaging_cost + recipeCostPerItemLow * doc.recipe_qty;
    let ingredientLabelFile = "";
    if (doc.ingredient_label_file) {
      ingredientLabelFile =
        "https://drive.google.com/uc?export=view&id=" +
        doc.ingredient_label_file;
    }
    let nameLabelFile = "";
    if (doc.name_label_file) {
      nameLabelFile =
        "https://drive.google.com/uc?export=view&id=" + doc.name_label_file;
    }

    return (
      <tr key={docRef} ref={this.state.refMap.get(docRef)} id={docRef}>
        <td>{doc.name}</td>
        <td>{roundToDollars(doc.price)}</td>
        <td>{doc.on_website ? "Y" : "N"}</td>
        <td>{roundToDollars(productCostHigh)}</td>
        <td>{roundToDollars(productCostLow)}</td>
        <td>{this.state.recipeMap.get(doc.recipe).name}</td>
        <td>{doc.recipe_qty}</td>
        <td>{roundToDollars(recipeCostPerItemHigh)}</td>
        <td>{roundToDollars(recipeCostPerItemLow)}</td>
        <td>{this.state.recipeMap.get(doc.recipe).low_profit_index}</td>
        <td>{roundToDollars(this.state.recipeMap.get(doc.recipe).total_ingredient_cost)}</td>
        <td>{roundToDollars(doc.packaging_cost)}</td>
        <td>
          {/* <Stack direction="horizontal" gap={2}> */}
          <div>
            <Button
              className="buttons"
              variant="dark"
              size="sm"
              onClick={() => {
                this.handleView(docRef);
              }}
            >
              View
            </Button>
            <Button
              className="buttons"
              variant="dark"
              size="sm"
              onClick={() => {
                this.toggleDeleteModal(docRef, doc.description);
              }}
            >
              Delete
            </Button>
            <Button
              className="buttons"
              variant="dark"
              size="sm"
              onClick={() => {
                this.handleUpdate(docRef);
              }}
            >
              Update
            </Button>
            {doc.ingredient_label_file && (
              <a href={ingredientLabelFile} download={ingredientLabelFile}>
                <Button className="buttons" variant="dark" size="sm">
                  Ingredient & Disclaimer Labels
                </Button>
              </a>
            )}
            {doc.name_label_file && (
              <a href={nameLabelFile} download={nameLabelFile}>
                <Button className="buttons" variant="dark" size="sm">
                  Name Labels
                </Button>
              </a>
            )}
          </div>
          {/* </Stack> */}
        </td>
      </tr>
    );
  };

  render() {
    let docMap = this.state.docMap;

    return (
      <>
        {
          /* 
          * Product List
          */
        }
        <Stack gap={3}>
          {this.state.showForm &&
            <ProductForm
              recipeMap={this.state.recipeMap}
              docRef={this.state.docRef}
              mode={this.state.mode}
              toggleForm={() => {
                this.toggleShowForm();
              }}
            />
          }
          {!this.state.showForm &&
            <Container>
              <Row className="justify-content-md-left">
                <Col>
                  <h2>Products:</h2>
                </Col>
                <Col>
                  <Button variant="dark" onClick={() => { this.toggleMode('Add') }}>Add New Product</Button>
                </Col>
                 <Col md="auto">
                  Find:
                </Col>
                <Col md="auto">
                  <CustomizedTypeahead
                    onChange={(selected) => {
                      console.log("selected" + selected);
                      if (selected.length > 0) {
                        let ref = this.state.refMap.get(selected[0].id);
                        this.scrollToView(ref);
                      }
                    }}
                    align='left'
                    labelKey={'value'}
                    options={this.state.options}
                  />
                </Col>
              </Row>
            </Container>
          }
          <Modal
            className="modalDialog"
            isOpen={this.state.isDeleteConfirmOpen}
            onRequestClose={this.toggleModal}
            contentLabel="My dialog"
          >
            <div>Delete {this.state.description}?</div>
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                this.toggleDeleteModal();
              }}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                this.handleDelete(this.state.deleteDocRef);
              }}
            >
              Confirm
            </Button>
          </Modal>
          {!this.state.showForm && (
            <div className="scrollingTable">
              <Table bordered hover>
                <thead>
                  <tr className='header'>
                    <th className='table-header'>Name</th>
                    <th className='table-header'>Our Price</th>
                    <th className='table-header'>Website?</th>
                    <th className='table-header'>Cost High</th>
                    <th className='table-header'>Cost Low</th>
                    <th className='table-header'>Recipe</th>
                    <th className='table-header'>Quantity per Product Pkg</th>
                    <th className='table-header'>Recipe Cost High</th>
                    <th className='table-header'>Recipe Cost Low</th>
                    <th className='table-header'>Recipe Low Profit Index</th>
                    <th className='table-header'>Recipe Ingredient Cost</th>
                    <th className='table-header'>Packaging Cost</th>
                    <th className='table-header'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {[...docMap].map(([key, value]) => {
                    return this.renderTableRow(key, value);
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Stack>
      </>
    );
  }
}
