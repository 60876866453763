import * as React from 'react';
import './../index.css';
import './../fonts/Bakerie/BakerieSmoothCondensed-Black.otf';
import { getDoc, setDoc, doc } from "firebase/firestore";
import { database } from '../firebase-config';
import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Modal
} from "react-bootstrap";

export class IngredientForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      description: "",
      cost: "",
      per: "",
      total_price: "",
      total_quantity: "",
      information: "",
      docs: [],
      validated: false,
      isOpen: false,
      modalString: "",

    };

    this.changeValue = this.changeValue.bind(this);
    this.COLLECTION = "ingredients";
  }

  handleClose = () => this.setState({ isOpen: false });

  async getDocumentFromDB() {
    try {
      let docRef = doc(database, this.COLLECTION, this.props.docRef);
      let existingDoc = await getDoc(docRef);
      this.setState({
        description: existingDoc.data().description,
        cost: existingDoc.data().cost,
        per: existingDoc.data().per,
        total_price: existingDoc.data().total_price,
        total_quantity: existingDoc.data().total_quantity,
        information: existingDoc.data().information,
      });
    } catch (e) {
      console.log("Error getting document ref:" + this.props.docRef);
    }

  }

  componentDidMount() {
    if (this.props.docRef !== '') {
      this.getDocumentFromDB();
    }
  }

  changeValue(event) {
    if (event.target.id === 'description') {
      this.setState({ description: event.target.value });
    } else if (event.target.id === 'cost') {
      this.setState({ cost: event.target.value });
    } else if (event.target.id === 'perselect') {
      this.setState({ per: event.target.value });
    } else if (event.target.id === 'totalprice') {
      if (event.target.value !== ''
        && this.state.total_quantity !== ''
        && typeof parseFloat(event.target.value) == 'number'
        && typeof parseInt(this.state.total_quantity) == 'number'
        && parseInt(this.state.total_quantity) !== 0) {
        let cost = Math.round(parseFloat(event.target.value) / parseInt(this.state.total_quantity) * 100) / 100;
        this.setState({ cost: cost });
      }
      this.setState({ total_price: event.target.value });
    } else if (event.target.id === 'totalqty') {
      if (event.target.value !== ''
        && this.state.total_price !== ''
        && typeof parseFloat(event.target.value) == 'number'
        && typeof parseInt(this.state.total_price) == 'number'
        && parseInt(this.state.total_quantity) !== 0) {
        let cost = Math.round(parseFloat(this.state.total_price) / parseFloat(event.target.value) * 100) / 100;
        this.setState({ cost: cost });
      }
      this.setState({ total_quantity: event.target.value });
    } else if (event.target.id === 'information') {
      this.setState({ information: event.target.value });
    }
  }

  changeDoc = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    let docData = {
      description: this.state.description,
      cost: this.state.cost,
      per: this.state.per,
      total_price: this.state.total_price,
      total_quantity: this.state.total_quantity,
      information: this.state.information
    };

    try {
      let documentName = '';
      if (this.props.docRef === '') {
        const regex2 = /[^A-Za-z0-9_]/ig;
        documentName = this.state.description.replaceAll(regex2, '_');
      } else {
        documentName = this.props.docRef;
      }

      const docRef = await setDoc(doc(database,
        this.COLLECTION, documentName), docData, { merge: true });
    } catch (e) {
      console.log("Error saving Packaging:", e);
    }

    this.setState({
      description: "",
      cost: "",
      per: "",
      total_price: "",
      total_quantity: "",
      information: "",
      validated: false,
      isOpen: true,
      modalString: "Packaging added!",
    });
    /**
     * toggle the show form so the add/edit is not shown.
     */
    this.props.toggleForm();
  }

  render() {
    return (
      <>
        {
          /* 
          * Packaging Add
          */
        }
        <Container>
          <Modal show={this.state.isOpen} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Ingredients</Modal.Title>
            </Modal.Header>
            <Modal.Body>Ingredient Added!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <h1>{this.props.mode} Ingredient</h1>
          <Form noValidate validated={this.state.validated} onSubmit={this.changeDoc}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="description">Description:</Form.Label>
              <Form.Control
                required
                value={this.state.description}
                placeholder="Description"
                id="description"
                onChange={this.changeValue}
              />
            </Form.Group>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="cost">Cost:</Form.Label>
              <Form.Label as={Col} htmlFor="perselect">Per:</Form.Label>
            </Row>
            <Row className="mb-3">
              <InputGroup as={Col} className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  value={this.state.cost}
                  aria-label="Cost"
                  placeholder="0.00"
                  id="cost"
                  //onChange={this.changeValue}
                  disabled
                />
              </InputGroup>
              <Form.Group as={Col}>
                <Form.Select
                  required
                  value={this.state.per}
                  id="perselect"
                  aria-label="Default select example"
                  onChange={this.changeValue}
                >
                  <option></option>
                  <option value="cup">1 cup</option>
                  <option value="teaspoon">1 teaspoon</option>
                  <option value="tablespoon">1 tablespoon</option>
                  <option value="ounce">1 ounce</option>
                  <option value="piece">1 piece</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Label as={Col} htmlFor="totalprice">Total Purchase Price:</Form.Label>
              <Form.Label as={Col} htmlFor="totalqty">Total Purchased Quantity:</Form.Label>
            </Row>

            <Row className="mb-3">
              <InputGroup as={Col} className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  required
                  value={this.state.total_price}
                  aria-label="totalprice"
                  placeholder="0.00"
                  id="totalprice"
                  onChange={this.changeValue}
                />
              </InputGroup>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  required
                  value={this.state.total_quantity}
                  placeholder="0"
                  id="totalqty"
                  onChange={this.changeValue}
                />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="info">Information:</Form.Label>
              <Form.Control
                value={this.state.information}
                as="textarea"
                id="information"
                onChange={this.changeValue}
              />
            </Form.Group>
            <Button variant="dark" onClick={() => { this.props.toggleForm() }}>
              Cancel
            </Button>{' '}
            <Button variant="dark" type="submit">
              Save
            </Button>
          </Form>
        </Container>
      </>
    );

  }
}
