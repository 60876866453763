import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export class CustomizedTypeahead extends Component {
  render() {
    let injectedProps = {};

    injectedProps.clearButton = true;

    return <Typeahead {...this.props} {...injectedProps} />
  }
}